import React from 'react'
import { Footer, Header } from './containers'
import { BrowserRouter, Route, Routes, Links } from "react-router-dom";
import About from './Conponent/about/About';
import Terms from './Conponent/terms/Terms'
import Privacy from './Conponent/privacy/Privacy'
import  Contact from './Conponent/contact/Contact'
import  Press from './Conponent/press/Press'
import Services from './Conponent/services/Services';
import Support from './Conponent/support/Support';
import Cookie from  './Conponent/cookie/Cookie'
import Professional from './Conponent/professional/Professional';
import Safety from './Conponent/safety/Safety';
import Change from './Conponent/change/Change';
import Delete from './Conponent/delete/Delete';
import Setting from './Conponent/setting/Setting';
import Visibility from  './Conponent/visibility/Visibility';
import Subscription from './Conponent/subscription/Subscription';
import Job from './Conponent/job/Job';
import Eula from './Conponent/eula/Eula';

// import './App.css'

const App = () => {
  return(
    <BrowserRouter>
    <Routes>
   
      <Route path = "/" element = {<Header/>}></Route>
      <Route path = "footer" element = {<Footer/>}></Route>
      <Route path = "about" element = {<About/>}></Route>
      <Route path = "terms" element = {<Terms/>}></Route>
      <Route path = "privacy" element = {<Privacy/>}></Route>
      <Route path = "contact" element = {<Contact/>}></Route>
      <Route path = "press" element = {<Press/>}></Route>
      <Route path = "services" element = {<Services/>}></Route>
      <Route path = "support" element = {<Support/>}></Route>
      <Route path = "cookie" element = {<Cookie/>}></Route>
      <Route path = "professional" element = {<Professional/>}></Route>
      <Route path = "safety" element = {<Safety/>}></Route>
      <Route path =  "change" element = {<Change/>}></Route>
      <Route path = "delete"  element = {<Delete/>}></Route>
      <Route path = "setting"  element = {<Setting/>}></Route>
      <Route path = "visibility" element ={<Visibility/>}></Route>
      <Route path =  "subscription" element ={<Subscription/>}></Route>
      <Route path = "job" element ={<Job/>}></Route>
      <Route path = "eula" element ={<Eula/>}></Route>
    </Routes>
    <Footer/>
    </BrowserRouter>
     
  )
};


export default App;